import ExamInformationMixin from '@/shared/mixins/exam-information.mixin'
import ExamObservation from '@/shared/mixins/exam-observation.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import CONSTANTS from '../../../../shared/constants/constants'
import iconProfile from '@/assets/svg/profile.svg?raw'

export default {
  name: 'exam-details-information',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mixins: [
    DateMixin,
    ExamInformationMixin,
    RoleMixin,
    AuthMixin,
    ExamObservation
  ],
  data () {
    return {
      instructionText: this.$i18n.t('type_here_pendency_answer'),
      listObservation: [this.$i18n.t('exam_reason'), this.$i18n.t('patient_clinical_history'), this.$i18n.t('medications_in_use')],
      STATUS: CONSTANTS.STATUS,
      informations: [],
      roles: [],
      iconProfile,
      creationDate: null,
      pendencyDescription: '',
      newInformation: '',
      modelEmergency: false
    }
  },
  computed: {
    formattedInformations () {
      return this.informations.map((information) => {
        const role = this.roles.find((role) => {
          return information.role === role.name
        })

        information.role = role.label
        information.creationDate = this.getFormattedDateHour(information.creationDate)
        return information
      })
    },
    currentUser () {
      if (!this.roles.length) {
        return
      }

      const user = this.getUser()
      const currentRole = this.roles.find((role) => {
        return user.role.name === role.name
      })

      return {
        name: user.fullname,
        role: currentRole.label
      }
    },
    hasSendInformation () {
      const user = this.getUser()
      return (user.role.name === 'source') && (this.details.status.id === this.STATUS.AGUARDANDO_INFORMACAO)
    }
  },
  async mounted () {
    this.$root.$on('updateModelEmergency', (data) => this.updateModelEmergency(data))
    this.$root.$emit('showLoading')
    try {
      if (this.details.status.id === this.STATUS.AGUARDANDO_INFORMACAO) {
        const res = await Promise.all([this.getRoles(), this.getInformationsByExamId(this.details.examId)])
        this.roles = res[0]
        this.informations = res[1].data
        this.pendencyDescription = [...res[1].data].pop()
      }
    } finally {
      this.$root.$emit('hideLoading')
    }
  },
  methods: {
    updateModelEmergency(modelEmergency) {
      this.modelEmergency = modelEmergency
    },
    clickStatus () {
      this.details.status.id === this.STATUS.AGUARDANDO_INFORMACAO ? this.clickSendInformation() : this.addObservation()
    },
    async clickSendInformation (information) {
      this.$root.$emit('showLoading')
      try {
        this.informations = await this.sendInformation(this.details.examId, this.newInformation)

        this.$emit('sendInformation')
        this.$router.go()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    pendencies () {
      const verify = this.qtdPendency >= 2 ? 2 : 1
      return this.$i18n.tc('pendencies', verify, { amount: this.qtdPendency })
    },

    async addObservation () {
      const observation = {
        content: this.newInformation,
        examId: this.details.examId,
        creationDate: this.getToday(),
        nameUser: this.getFullName()
      }

      this.$root.$emit('showLoading')
      try {
        const result = await this.addExamObservation(observation)
        const statusId = result.data.data.status.id
        this.details.observations = [observation]
        this.$emit('addObservation', statusId)
        this.$router.go()
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
